:root
{
  --anim-duration: 500ms;
  --slow-anim_duration: 1500ms;
}

.hidden /* Animated elements with animations that starts transparent should be hidden to prevent pop-up */
{
  visibility: hidden;
}

.slow-anim /* It's meant to override others animations duration */
{
    animation-duration: var(--slow-anim_duration) !important;
}

.leftToRightSlideAnim
{
    animation-name: leftToRight;
    animation-duration: var(--anim-duration);
}

.rightToLeftSlideAnim
{
    animation-name: rightToLeft;
    animation-duration: var(--anim-duration);
}

.bottomToTopSlideAnim
{
    animation-name: bottomToTop;
    animation-duration: var(--anim-duration);
}

.fadeInAnim
{
    animation-name: fadeIn;
    animation-duration: var(--anim-duration);
}

.beatingAnim
{
    animation-name: beating;
    animation: beating 1s infinite alternate;
}

.back-to-top 
{
  position: fixed;
  display: none;
  text-align: center;
  right: 30px;
  bottom: 30px;
  z-index: 11;
  animation: action 1s infinite alternate;
}

.loader
{
  margin: auto;
  margin-top: calc(40vh - var(--navbar-height));
  border: 8px solid #EAF0F6;
  border-radius: 50%;
  border-top: 8px solid var(--accent-color);
  width: 80px;
  height: 80px;
  animation: spinner 4s linear infinite;
}

@keyframes spinner
{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes leftToRight 
{
  0%   { transform: scaleX(1.2) translateX(-100px); opacity: 0; }
  100% { transform: scaleX(1) translateX(0px); opacity: 1; }
}

@keyframes rightToLeft 
{
  0%   { transform: scaleX(1.2) translateX(100px); opacity: 0; }
  100% { transform: scaleX(1) translateX(0px); opacity: 1; }
}

@keyframes bottomToTop 
{
  0%   { transform: scaleY(1.5) translateY(100px); opacity: 0; }
  100% { transform: scaleY(1) translateY(0px); opacity: 1; }
}

@keyframes fadeIn 
{
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes action 
{
  0%   { transform: translateY(0); }
  100% { transform: translateY(-15px); }
}

@keyframes beating
{
	to { transform: scale(1.2); }
}