/* Dark mode */
@media (prefers-color-scheme: dark)
{
    :root
    {
        --dark-mode: "true";
        --text-color: var(--text-color-dark);
        --background-variant1: var(--background-dark-variant1);
        --background-variant2: var(--background-dark-variant2);
    }
}

/* Light mode */
@media (prefers-color-scheme: light) 
{
    :root
    {
        --dark-mode: "false";
        --text-color: var(--text-color-light);
        --background-variant1: var(--background-light-variant1);
        --background-variant2: var(--background-light-variant2);
    }
}
 
:root
{
    --dark-blue: #0E151B;
    --navbar-height: 5rem;
    --accent-color: #009cff;
    --banner-height: 100vh;

    --text-color-dark: #e8e8e0;
    --text-color-light: #22395d;
    --background-dark-variant1: #060D13;
    --background-dark-variant2: #0A1117;
    --background-light-variant1: white;
    --background-light-variant2: whitesmoke;

    /* Bootstrap var overrides */
    --bs-primary: var(--accent-color) !important;
    --bs-link-color: var(--accent-color) !important;
    --bs-body-bg: var(--background-variant1) !important;
    --bs-body-color: var(--text-color) !important;
    --bs-body-font-family: "OpenSans" !important
}

@font-face 
{
    font-family: Anton-Regular;
    src:url("../fonts/Anton-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face 
{
    font-family: OpenSans;
    src:url("../fonts/OpenSans.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

html, body
{
    overflow-x: clip;
}

button
{
    color: var(--text-color) !important;
    border: 0 !important;
    border-radius: 2rem !important;
}

h1, h2:not(.bigFont), h3, h4, h5, .btn
{
    font-family: Anton-Regular !important;
}

table, th, td
{
    border: 1px solid !important;
    padding: 0.5rem;
}

img
{
    object-fit: cover;
}

footer 
{
    position: relative;
    background-color: var(--dark-blue);
    color: aliceblue;
    padding: 1rem;
    z-index: 15;
}

#home
{
    position:relative;
    z-index: 10;
}

#banner
{
  position: relative;
  text-align: center;
  color: white;
}

#bannerImg
{
    height: calc(var(--banner-height) - var(--navbar-height));
    width: 100vw;
    object-position: 40% 20%;
}

#bannerContent
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#navbar
{
    position: sticky;
    top: 0;
    background-color: var(--dark-blue);
    color: aliceblue;
    z-index: 100; /* Nothing over navbar, exept for cookie consent banner */
}

#PageNotFound
{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#LoadingScreen
{
    height: 100vh;
}

#scrollIndicator 
{
  position:fixed;
  bottom: calc(var(--navbar-height));
  width: 100%;
  z-index: 5;
  transform: translate(-50%, -50%);
  animation: action 1s infinite alternate;
}

#scrollIndicator::before
{
  content: url("../images/svg/expand_more_FILL1_wght700_GRAD200_opsz48.svg");
  width: 2rem;
  height: 2rem;
}


.bigFont
{
    /* slope = (maxFontSize - minFontSize) / (maxWidth - minWidth) */
    /* yAxisIntersection = -minWidth * slope + minFontSize */
    /* preferredValue = yAxisIntersection[rem] + (slope * 100)[vw] */
    font-size: clamp(2rem, 0.7848rem + 2.5316vw, 3rem);
}

.biggerFont
{
    /* slope = (maxFontSize - minFontSize) / (maxWidth - minWidth) */
    /* yAxisIntersection = -minWidth * slope + minFontSize */
    /* preferredValue = yAxisIntersection[rem] + (slope * 100)[vw] */
    font-size: clamp(4rem, 2.7848rem + 2.5316vw, 5rem);
}

.navbarHeight
{
    height: var(--navbar-height); 
    line-height: var(--navbar-height);
}

.fixedFooter
{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.btn-link
{
    text-decoration: none !important;
    color: white !important;
}

.btn-link:hover
{
    color: gray !important;
}

.backgroundVariant1
{
    background-color: var(--background-variant1);
}

.backgroundVariant2
{
    background-color: var(--background-variant2);
}

.accentColor
{
    color: var(--accent-color);
}

.ampersand:not(p span)
{
    display: inline;
    font-size: 50%;
    vertical-align: middle;
}

.ampersand::after
{
    content: " & ";
}

.btn-primary
{
    background-color: var(--accent-color) !important;
    border-style: hidden !important;
}